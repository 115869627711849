<template>
  <div class="container-fluid">
    <div class="row">
      <!-- Tab cards -->
      <div class="col">
        <h4 class="fw-bold text-primary">Export Clinic Data</h4>
        <p class="bg-light p-3 mb-4">
          Export all the data from your Swandoola account, this action
          generates a downloadable file in the JSON format for easy import
          into other systems.
          <br />
          Once you begin the export below, your file will be prepared and
          stored behind the scenes, you will receive an email to
          <strong>{{ $store.getters['auth/user'].email }}</strong> when the file is ready
          for download.

          <br />

          If you wish to only export a single client for testing purposes,
          use the search below and select the client you would like to
          export. If no client is selected <strong>all</strong> clients will
          be exported.
        </p>
        <div class="row mb-4">
          <div class="col-md-6 my-auto">
            <input
              v-if="!client"
              type="text"
              v-model="search"
              class="form-control form-control-sm"
              placeholder="Search for a client..."
              @input="findClient"
            />

            <button
              v-else
              class="btn btn-outline-danger btn-sm"
              @click="client = null"
            >
              <i class="far fa-times me-2"></i>{{ client.name }}
            </button>

            <ul class="list-group" v-if="clients.length > 0">
              <li
                class="list-group-item cursor-pointer"
                v-for="client in clients"
                :key="client.id"
                @click="selectClient(client)"
              >
                {{ client.name }}
                {{ client.email ? "/ " + client.email : "" }}
              </li>
            </ul>
          </div>
          <div class="col-auto my-auto ms-auto">
            <button class="btn btn-primary" @click="exportData">
              <i class="far fa-download me-2"></i>Export Data
            </button>
          </div>
        </div>

        <small
          ><i class="">
            We use the JSON schema to create an array of related data such
            as clients with their notes, files, appointments, orders and so
            on.</i
          ></small
        >
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "debounce";


export default {
  props: [],
  data() {
    return {
      emailInUse: false,
      saving: false,
      pendingTfaConfirm: false,
      confirmTfaCode: null,
      clients: [],
      search: "",
      client: null
    };
  },
  methods: {
    selectClient(client) {
      this.client = client;
      this.clients = [];
      this.search = "";
    },
    async findClient() {
      if (this.search.length > 3) {
        const { data } = await this.$axios.post(
          process.env.VUE_APP_API_URL + "/settings/client-search",
          { search: this.search }
        );

        this.clients = data;
      } else {
        this.clients = [];
      }
    },
    async exportData() {
      const { data } = await this.$axios.post(
        process.env.VUE_APP_API_URL + "/settings/start-export",
        { client_id: this.client ? this.client.id : null }
      );
      this.client = null;
      this.$EventBus.$emit("alert", { message: data.message });
    },
    updateEmail() {
      this.saving = true;
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/settings/user/" +
            this.user.id +
            "/update-email",
          this.user
        )
        .then(({ data }) => {
          this.$EventBus.$emit("refreshUser");
          this.$EventBus.$emit("alert", data);
          this.saving = false;
        });
    },
    updatePhone: debounce(function() {
      const original = this.user.phone;
      this.saving = true;
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/settings/user/" +
            this.user.id +
            "/update-phone",
          this.user
        )
        .then(({ data }) => {
          this.$EventBus.$emit("refreshUser");
          this.$EventBus.$emit("alert", data);
          this.saving = false;
        });
    }, 800),
    updateTfa() {
      this.saving = true;
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/settings/user/" +
            this.user.id +
            "/update-tfa",
          this.user
        )
        .then(({ data }) => {
          this.saving = false;
          if (data.success) {
            this.pendingTfaConfirm = true;
          }
          this.$EventBus.$emit("refreshUser");
          this.$EventBus.$emit("alert", data);
        });
    },
    confirmTfa() {
      this.saving = true;
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/settings/user/" +
            this.user.id +
            "/confirm-tfa",
          {
            tfa_code: this.confirmTfaCode
          }
        )
        .then(({ data }) => {
          this.saving = false;
          if (data.success) {
            this.pendingTfaConfirm = false;
          }
          this.$EventBus.$emit("refreshUser");
          this.$EventBus.$emit("alert", data);
        });
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$stash.path;
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    }
  },
  mounted() {},
};
</script>



<style>
</style>
